import Onboard from '@web3-onboard/core';
import injectedModule from '@web3-onboard/injected-wallets';
import walletConnectModule from '@web3-onboard/walletconnect';
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";

const wallets = {
  metamask: "MetaMask",
  coinbaseWallet: "Coinbase Wallet",
  walletConnect: "WalletConnect"
}

async function reconnectInjectedWallet() {
  let provider = getEthereumProvider();
  let results = await provider.request({
    method: "wallet_requestPermissions",
    params: [{
      eth_accounts: {}
    }]
  });

  if (results.length > 0) {
    return results.at(0).caveats?.at(0).value?.at(0);
  }

  return null;
}

async function connectWithWeb3Onboard(walletName) {
  const injected = injectedModule();
  const walletConnectSdk = new walletConnectModule({
    projectId: "0da843d7d94fd76cbb78f33705b92016",
    qrModalOptions: {
      themeVariables: {
        "--wcm-z-index": "200"
      }
    },
    dappUrl: "https://app.coinledger.io"
  });
  const onboard = Onboard({
    appMetadata: {
      name: "CoinLedger",
      icon: "https://app.coinledger.io/assets/common/brand-icon.svg",
      description: "Calculate Your Crypto Taxes in 20 Minutes. Instant Crypto Tax Forms. Support For All Exchanges, NFTs, DeFi, and 10,000+ Cryptocurrencies."
    },
    wallets: [ injected, walletConnectSdk ],
    chains: [
      {
        id: '0x1',
        token: 'ETH',
        label: 'Ethereum Mainnet',
        rpcUrl: 'https://cloudflare-eth.com'
      },
      {
        id: '0x38',
        token: 'BNB',
        label: 'Binance Smart Chain',
        rpcUrl: 'https://bsc-dataseed.binance.org/'
      },
      {
        id: '0x89',
        token: 'MATIC',
        label: 'Matic Mainnet',
        rpcUrl: 'https://matic-mainnet.chainstacklabs.com'
      },
      {
        id: 10,
        token: 'OETH',
        label: 'Optimism',
        rpcUrl: 'https://mainnet.optimism.io'
      },
      {
        id: 42161,
        token: 'ARB-ETH',
        label: 'Arbitrum',
        rpcUrl: 'https://rpc.ankr.com/arbitrum'
      }
    ]
  });

  let options = walletName ? { autoSelect: walletName } : undefined;
  await onboard.connectWallet(options);

  const [ primaryWallet ] = onboard.state.get().wallets;

  if (primaryWallet) {
    await onboard.disconnectWallet({ label: primaryWallet.label });
    return primaryWallet.accounts[0].address;
  }

  return null;
}

// Gets the window ethereum provider with a preference toward MetaMask if it exists.
function getEthereumProvider() {
  let provider = window.ethereum;
  if (window.ethereum.providers?.length) {
    window.ethereum.providers.forEach(p => {
      if (p.isMetaMask) {
        provider = p;
      }
    });
  }

  return provider;
}

function isConnectedToMetaMask() {
  let provider = getEthereumProvider();
  if (!provider) {
    return false;
  }

  // Some browsers with MetaMask won't always have the isConnected function if another wallet like Coinbase Wallet is also injecting a provider.
  if (provider.isConnected) {
    return provider.isConnected();
  }

  // Alternative logic if isConnected gets overwritten.
  return provider.isMetaMask && !!provider.selectedAddress;
}

async function connectWithCoinbaseSdk() {
  const coinbaseWallet = new CoinbaseWalletSDK({
    darkMode: true,
    appName: "CoinLedger",
    appLogoUrl: "https://app.coinledger.io/assets/common/brand-icon.svg",
    reloadOnDisconnect: false,
  });

  const response = await coinbaseWallet.makeWeb3Provider('https://cloudflare-eth.com', 1).request({ method: 'eth_requestAccounts' });

  coinbaseWallet.disconnect();
  return response[0];
}

window.connectToWallet = async function(walletName) {
  try {
    let address;
    // If Coinbase Wallet is selected, use Coinbase SDK instead of BlockNative integration.
    if (walletName === wallets.coinbaseWallet) {
      address = await connectWithCoinbaseSdk();
    } else if (walletName === wallets.metamask && isConnectedToMetaMask()) {
      // If user is already connected to MetaMask, a call to wallet_requestPermissions is needed instead of eth_requestAccounts.
      address = await reconnectInjectedWallet();
    } else {
      // Default is to use BlockNative to retrieve the wallet address.
      address = await connectWithWeb3Onboard(walletName);
    }

    return {
      Address: address,
      Error: null
    }
  } catch (ex) {
    return {
      Address: null,
      Error: ex.error ? ex.error.message : ex.message
    }
  }
}
