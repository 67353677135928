/** Constants **/
const DOM_CONTENT_LOADED_EVENT = 'DOMContentLoaded';

/** Styles **/
import './css/blazor.css';
import '../../CoinLedger.UI.Blazor.Components/wwwroot/css/tailwind.css';
import '../../CoinLedger.UI.Blazor.Components/wwwroot/css/loaders/wave.css';

/** Polyfills **/
// [WEB-1143] - intersection observer is used by the Virtualize component in Blazor core.
import '../../CoinLedger.UI.Blazor.Components/wwwroot/lib/intersection-observer';

/** Scripts **/
import './js/individual/common/checkout.js';
import './js/billing/affiliate_tracking.js';
import './js/individual/common/connectWallet.js';
// import * as zendesk from './js/integrations/zendesk.js';
import './js/integrations/clarity.js';
import '../../CoinLedger.UI.Blazor.Components/wwwroot/js/windowHelpers.js';
import '../../CoinLedger.UI.Blazor.Components/wwwroot/js/elementReference.js';
import '../../CoinLedger.UI.Blazor.Components/wwwroot/js/chartsJSInterop.js';
import '../../CoinLedger.UI.Blazor.Components/wwwroot/js/downloadHelper.js';
import '../../CoinLedger.UI.Blazor.Components/wwwroot/js/isValidBrowser.js';
import '../../CoinLedger.UI.Blazor.Components/wwwroot/js/intersectionObserverManager.js';
import '../../CoinLedger.UI.Blazor.Components/wwwroot/js/pageHideInterop.js';

/** Components **/
import '../../CoinLedger.UI.Blazor.Components/Common/VirtualScroll/VirtualScroll'
import '../../CoinLedger.UI.Blazor.Components/Common/Inputs/Select/Select'

/** Vendors **/
import '../../CoinLedger.UI.Blazor.Components/wwwroot/lib/chart.js';
import '../../CoinLedger.UI.Blazor.Components/wwwroot/lib/chartjs-adapter-luxon';
import '../../CoinLedger.UI.Blazor.Components/wwwroot/lib/luxon';
import '../../CoinLedger.UI.Blazor.Components/wwwroot/lib/bowser';

/** Service Workers **/
window.addEventListener(DOM_CONTENT_LOADED_EVENT, () => {
  if ('serviceWorker' in navigator) {
    const version = document.querySelector("meta[name='coinledger-version']")?.getAttribute("content");
    navigator.serviceWorker.register(`blazor-worker.js?version=${version}`);
    navigator.serviceWorker.ready.then(registration => {
      registration.active.postMessage("Boot");
    });
  }
});

/* Get User */
window.getUser = async () => {
  const USER_ENDPOINT = '/bff/user';

  // Fetch user claims
  return fetch(USER_ENDPOINT, {
    headers: {
      'X-CSRF': '1'
    }
  });
}

/** Initial Load Auth Check **/
window.addEventListener(DOM_CONTENT_LOADED_EVENT, async (event) => {
  const LOGIN_ENDPOINT = '/bff/login';
  const LOGOUT_ENDPOINT = '/bff/logout';
  const INVITATION_ID = 'invitationId';
  const ACCEPTED_INVITATION_ID = "acceptInvitationId";
  const SUPPORT_URL_PATH = '/support/';
  const REFERRER = "referrer";
  const PARTNER_TOKEN = "partnerToken";
  const BYPASS_PARTNER_LOGOUT = "bypassPartnerLogout";
  const USER_TYPE = "userType";
  const USER_EMAIL_HINT = "userEmailHint";

  console.log('Attempting to resolve user claims on application load.');

  // Get current user
  const params = new URLSearchParams(window.location.search);
  const userClaimsResponse = await getUser();

  if (userClaimsResponse.status === 200
    && params.has(REFERRER)
    && params.has(PARTNER_TOKEN)
    && !params.has(BYPASS_PARTNER_LOGOUT)) {

    // Force logout on specific partner scenarios
    console.log('Partner scenario detected. Forcing logout redirect...');

    // Get Logout Url
    const claims = await userClaimsResponse.json();
    const logoutUrlClaim = claims.filter(o => o.type === "bff:logout_url")[0];

    // Build Return Url
    let returnUrl = window.location.href.toString();
    returnUrl += `&${BYPASS_PARTNER_LOGOUT}=true`;

    const logoutUrl = logoutUrlClaim === undefined
      ? `${LOGOUT_ENDPOINT}?returnUrl=${encodeURIComponent(returnUrl)}`
      : `${logoutUrlClaim.value}&returnUrl=${encodeURIComponent(returnUrl)}`;

    // Redirect To Url
    window.location.href = logoutUrl;
  }
  else if (userClaimsResponse.status === 401) {
    // Navigate to login
    console.log('Unauthenticated request to resolve user claims. Redirecting to login.');

    // Construct base login url
    console.log(`Current Url -> ${window.location.href}`);
    console.log(`Encoded Current Url -> ${encodeURIComponent(window.location.href)}`);

    let acrValues = '';
    let returnUrl = window.location.href.toString();
    console.log(`Initial Return Url -> ${returnUrl}`);

    /**
     * ACR Values
     */

    // Include invitation in ACR values
    if (params.has(INVITATION_ID)) {
      acrValues += "&" + ACCEPTED_INVITATION_ID + "=" + params.get(INVITATION_ID);
    }

    // When the referrer query param exists for an unauthenticated user, take them directly to the signup page.
    if (params.has(REFERRER)) {

      // Include 'referrer' in ACR values
      acrValues += `&referrer=${params.get(REFERRER)}`;

      // Include 'partnerToken' in ACR values
      if (params.has(PARTNER_TOKEN)) {
        acrValues += `&partnerToken=${params.get(PARTNER_TOKEN)}`;
      }

      // Include MetaMask Portfolio 'accounts' and 'networks' in ACR values
      if (params.has('accounts') && params.has('networks')) {
        acrValues += `&accounts=${params.get('accounts')}`;
        acrValues += `&networks=${params.get('networks')}`;
      }

      // Include 'userType' in ACR values
      acrValues += '&signup=';
      if (params.has(USER_TYPE)) {
        // User type signup
        acrValues += params.get(USER_TYPE);
      } else {
        // Default signup
        acrValues += 'individual';
      }

      // Include 'userEmailHint' in ACR values
      if (params.has(USER_EMAIL_HINT)) {
        acrValues += `&userEmailHint=${params.get(USER_EMAIL_HINT)}`;
      }
    }

    // Add IDP in ACR values
    if (window.location.href.indexOf(SUPPORT_URL_PATH) > -1) {
      acrValues += "&idp=InternalGoogle";
    }

    /**
     * Return Url Query Parameters
     */

    // Include 'bypassPartnerLogout' query param on partner scenario
    if (params.has(REFERRER) &&
      params.has(PARTNER_TOKEN) &&
      !params.has(BYPASS_PARTNER_LOGOUT)) {
      console.log('CoinStats referrer & partner token detected. Including logout bypass in url...');
      returnUrl += `&${BYPASS_PARTNER_LOGOUT}=true`;
    }

    // Redirect To Final Url
    let finalLoginUrl = acrValues === ''
      ? `${LOGIN_ENDPOINT}?returnUrl=${encodeURIComponent(returnUrl)}`
      : `${LOGIN_ENDPOINT}?returnUrl=${encodeURIComponent(returnUrl)}${acrValues}`;

    console.log(`Final Return Url: ${returnUrl}`);
    console.log(`Navigating To: ${finalLoginUrl}`);

    window.location.href = finalLoginUrl;
  }
});
